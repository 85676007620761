import React from 'react'

const getVideoID = url => {
  const YOUTUBE_SHORT_LINK = 'youtu.be/'
  const YOUTUBE_LINK = 'youtube.com/watch?v='

  if (url.includes(YOUTUBE_SHORT_LINK)) {
    const index = url.indexOf(YOUTUBE_SHORT_LINK) + YOUTUBE_SHORT_LINK.length
    return url.slice(index, url.length)
  }
  if (url.includes(YOUTUBE_LINK)) {
    const index = url.indexOf(YOUTUBE_LINK) + YOUTUBE_LINK.length
    return url.slice(index, url.length)
  }
  return ''
}

const YouTube = ({ url, title }) => {
  // If we have a title AND the title is not the video URL
  if (title !== '' && title !== url) {
    return (
      <a target="_blank" rel="noreferrer" href={url}>
        {title}
      </a>
    )
  }

  const videoId = getVideoID(url)
  return (
    <figure className="video">
      <iframe
        title={url}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </figure>
  )
}

export default YouTube
