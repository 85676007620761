import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import GoogleApiWrapper from '../components/GoogleMaps'
import YouTube from '../components/Blocks/YouTube'

const Event = ({ pageContext, location }) => {
  const {
    course,
    mainImage,
    latitude,
    longitude,
    eventDetails,
    when,
    where,
    locationType,
    eventURL,
    registrationURL,
    youtubeID,
    priceDesc,
    image2
  } = pageContext
  const coordinates = [parseFloat(latitude), parseFloat(longitude)]

  return (
    <Layout path={location.pathname}>
      <SEO title="Events" />
      <div className="container text-content">
        <Link to="/events" className="back-link">
          &larr; Events
        </Link>
        <div style={{ minHeight: '300px' }}>
          <img
            width="100%"
            style={{ backgroundColor: 'black' }}
            className="fade-in"
            src={mainImage}
            alt={course}
          />
        </div>
        <h3>{when}</h3>
        <h4>{where}</h4>
        {locationType && <h4>{locationType}</h4>}
        {eventURL && (
          <p>
            <a href={eventURL}>Event Link</a>
          </p>
        )}
        <div dangerouslySetInnerHTML={{ __html: eventDetails }} />

        {priceDesc && (
          <p>
            <strong>Cost: </strong>
            {priceDesc}
          </p>
        )}
        {registrationURL && (
          <a className="btn btn-secondary" href={registrationURL}>
            Register now
          </a>
        )}

        {youtubeID && (
          <div style={{ margin: '20px 0' }}>
            <YouTube url={`https://youtu.be/${youtubeID}`} title="" />
          </div>
        )}

        {image2 && (
          <img
            width="100%"
            style={{ backgroundColor: 'black' }}
            className="fade-in"
            src={image2}
            alt={course}
          />
        )}
      </div>
      <div className="map-container">
        <GoogleApiWrapper coordinates={coordinates} />
      </div>
    </Layout>
  )
}

export default Event

Event.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
